var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"clickable",attrs:{"name":"prospectsOverview","items":_vm.prospects,"headers":_vm.headers,"loading":_vm.isFetching},on:{"click:row":_vm.goToProject},scopedSlots:_vm._u([{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.companyName)+" ")]}},{key:"item.contactName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contactName)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalizeStart")(item.status))+" ")]}},{key:"item.statusUpdatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.statusUpdatedAt)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }