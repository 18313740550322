



























import { Component, Prop, Vue } from "vue-property-decorator";
import { ITableHeader } from "@/components/data-table/types";
import Project, { Prospect } from "@/entity/Project";
import Contact, { getContactFullName } from "@/entity/Contact";
import { formatUnixTimestamp } from "@/utils/date";
import ContactsService from "@/services/ContactsService";
import CompanyService from "@/services/CompanyService";

@Component
export default class ContactProspectsView extends Vue {
  @Prop({ required: true, type: Object }) contact!: Contact;

  isFetching = false;
  prospects: any[] = [];

  get headers(): ITableHeader[] {
    return [
      {
        text: this.$tc("id", 1),
        value: "id",
      },
      {
        text: this.$tc("projectName", 1),
        value: "companyName",
      },
      {
        text: this.$tc("owner", 1),
        value: "contactName",
      },
      {
        text: this.$tc("status", 1),
        value: "status",
      },
      {
        text: this.$tc("updatedAt", 1),
        value: "statusUpdatedAt",
      },
    ];
  }

  mapProspects(prospects: Prospect[]) {
    return prospects.map((prospect) => ({
      id: prospect.id,
      companyName: prospect.projectContact.name,
      contactName: getContactFullName(prospect.projectContact?.contact ?? null),
      status: prospect.prospectiveBuyer?.status
        ? this.$tc(prospect.prospectiveBuyer.status)
        : "",
      statusUpdatedAt: prospect.prospectiveBuyer?.statusUpdatedAt
        ? formatUnixTimestamp(prospect.prospectiveBuyer.statusUpdatedAt)
        : "",
    }));
  }

  goToProject(project: Project): void {
    this.$router.push({
      name: "projectDetail",
      params: {
        projectId: (project.id as number).toString(),
        projectType: project.projectType,
      },
    });
  }

  async loadData(): Promise<void> {
    try {
      this.isFetching = true;
      const response = await CompanyService.getProspects(
        this.contact.id as number
      );
      this.prospects = this.mapProspects(response.content);
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isFetching = false;
    }
  }

  async created(): Promise<void> {
    await this.loadData();
  }
}
